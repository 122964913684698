import React, {memo, useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import {Redirect, Route, Switch, useHistory, useLocation, useRouteMatch} from "react-router-dom";
import MainApp from "./MainApp";
import SignIn from "../SignIn";
import {setInitUrl,userSignIn,setIfDemo} from "../../appRedux/actions/Auth";

import api from '../../util/Api'

const RestrictedRoute = ({component: Component, location, token, ...rest}) =>
  <Route
    {...rest}
    render={props =>
      token
        ? <Component {...props} />
        :
        <Redirect
          to={{
            pathname: '/login',
            state: {from: location}
          }}
        />}
  />;


const AppContent = () => {

  const dispatch = useDispatch();
  const {token, initURL} = useSelector(({auth}) => auth);

  const location = useLocation();
  const history = useHistory();
  const match = useRouteMatch();


  useEffect(() => {
    let link = document.createElement('link');
    link.type = 'text/css';
    link.rel = 'stylesheet';
    link.href = "/css/style.css";
    link.className = 'gx-style';
    document.body.appendChild(link);
  });

  useEffect(() => {
    if (initURL === '') {
      dispatch(setInitUrl(location.pathname));
    }

    
  }, [dispatch, initURL,  location.pathname, location.search, ]);
  useEffect(() => {
    if (token !== null) {
      history.push('/');
    }
  }, [token, history]);

 useEffect(()=>{
  if (token) {
    api.defaults.headers.common['Authorization'] = `Bearer ${token}`;
   } else {
    delete api.defaults.headers.common['Authorization'];
   }
 },[])

  useEffect(() => {
   
    if(location.pathname === '/' || location.pathname === '/demo'){
    
      if (!token ) {
        if(location.pathname === '/demo'){
          dispatch(setIfDemo(true));
          dispatch(userSignIn({email:'demo@wajeez.co',password:'demo1234'}))
          
        }
       else history.push('/login');
      } else if (initURL === '' || initURL === '/' || initURL === '/login'||initURL === '/demo') {
        history.push('/dashboard');
        

      } else {
        history.push(initURL);
        
      }
    }
  }, [token, initURL, location, history]);


  return (
    
      
        <Switch>
          <Route exact path='/login' component={SignIn}/>
          <RestrictedRoute path={`${match.url}`} token={token} location={location}
                           component={MainApp}/>
        </Switch>
   
  
  )
};

export default memo(AppContent);
