import {
  IS_DEMO,
  FETCH_START,
  FETCH_SINGLE_ERROR,
  FETCH_SUCCESS,
  INIT_URL,
  SIGNOUT_USER_SUCCESS,
  USER_DATA,
  USER_TOKEN_SET,
  BUSINESS_DATA,
  HEADER,
  USER_ID,
  GROUP_ID,
} from "../../constants/ActionTypes";
import api from "../../util/Api";
import { updateLogo, updateThemeColor } from "./Setting";
import getTimezoneOffset from "../../util/getTimeZoneOffset";
import { history } from "../store";

export const setInitUrl = (url) => {
  return {
    type: INIT_URL,
    payload: url,
  };
};

export const setIfDemo = (bool) => {
  localStorage.setItem("isDemo", bool);
  return {
    type: IS_DEMO,
    payload: bool,
  };
};

export const userSignIn = ({ email, password }) => {
  return (dispatch) => {
    dispatch({ type: FETCH_START });
    api
      .post(
        "businesses/login",
        {
          email: email,
          password: password,
        },
        { headers: { "Accept-Language": "ar" } }
      )
      .then(async ({ data }) => {
        const headers = {
          "Accept-Language": "ar",
          timezone: getTimezoneOffset(),
          "Content-Type": "application/json",
          Authorization: "Bearer " + data.data.token,
        };
        localStorage.setItem("token", data.data.token);
        localStorage.setItem("group_id", data.data.group_id);
        localStorage.setItem("user", JSON.stringify(data.data));
        localStorage.setItem("Authorization", "Bearer " + data.data.token);
        dispatch({ type: FETCH_SUCCESS });
        api.defaults.headers.common[
          "Authorization"
        ] = `Bearer ${data.data.token}`;

        dispatch({ type: USER_DATA, payload: data.data });
        dispatch({ type: USER_ID, payload: data.data.id });
        dispatch({ type: GROUP_ID, payload: data.data.group_id });
        dispatch({ type: HEADER, payload: headers });
        dispatch({ type: USER_TOKEN_SET, payload: data.data.token });
        if (window.location.pathname === "/demo") {
          history.push("/dashboard");
          dispatch(setInitUrl("/dashboard"));
        }

        return data.data.group_id ? { id: data.data.group_id, headers } : null;
      })
      .then((data) => {
        data && dispatch(getBusinessData(data));
      })
      .catch((error) => {
        dispatch({
          type: FETCH_SINGLE_ERROR,
          payload: error.response.data.errors[0].localized_msg,
        });
      });
  };
};

export const getBusinessData = ({ id }) => {
  return async (dispatch) => {
    id &&
      (await api
        .get(`businesses/${id}`)
        .then(async (res) => {
          let color = res.data.data.primary_color
            ? res.data.data.primary_color?.split("-")
            : null;
          localStorage.setItem("business_data", JSON.stringify(res.data.data));
          localStorage.setItem("logo", JSON.stringify(res.data.data.logo));
          dispatch({ type: BUSINESS_DATA, payload: res.data.data });
          dispatch(updateLogo(res.data.data.logo));
          color && dispatch(updateThemeColor(color[0] + "-" + color[1]));

          (await color) &&
            localStorage.setItem(
              "theme",
              JSON.stringify(color[0] + "-" + color[1])
            );
        })
        .catch((err) => getBusinessData(id)));
  };
};

export const userSignOut = () => {
  return (dispatch) => {
    dispatch({ type: FETCH_START });

    localStorage.removeItem("token");
    localStorage.clear();
    dispatch({ type: FETCH_SUCCESS });
    dispatch({ type: SIGNOUT_USER_SUCCESS });
    window.location.reload();
  };
};
