import React, {useState } from "react";
import navigation from "./sidebarContent";
import { Link, useHistory, useLocation } from "react-router-dom";
import SidebarFooter from './sidebarFooter';
import SidebarHeader from './sidebarHeader';


function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}


const Sidebar = (props) => {
  const history = useHistory();
  const [navContent, setNavContant] = useState(navigation);
  const location = useLocation();
  const newSelect = (prop) => () => {
    const content = navContent.map((item) => {
      if (item.current === true) {
        return { ...item, current: false };
      } else return item;
    });
    content[prop] = { ...content[prop], current: true };
    setNavContant(content);
    history.push(content[prop].href);
  };

  return (
    <div class="flex h-full h-screen h-16 ">
      <div class="flex flex-col items-center h-full overflow-hidden text-gray-900 dark bg-white print-hidden w-60">
        <SidebarHeader props={props} />

        <div class="h-16"></div>

        

        <div class="w-full ps-2 justify-between h-full flex flex-col ">
          <div class="flex flex-col items-center w-full mt-1 border-gray-700">
            <nav className="ps-2  space-y-6 w-full  py-3">
              {navContent.map((item, i) => (
                <Link
                  key={i}
                  to={{
                    pathname: item.href,
                    state: { from: location },
                  }}
                >
                  <a
                    onClick={newSelect(i)}
                    key={item.name}
                    className={classNames(
                      item.href === location.pathname
                        ? ` border-e-4 rounded-sm border-${props.theme}`
                        : `text-gray-400  hover:text-gray-900`,
                     ` group  flex items-center  ps-2 py-1 mt-3 text-lg font-bold `
                    )}
                  >
                    <item.icon
                      className={classNames(
                        item.href === location.pathname
                          ? `text-${props.theme} `
                          : `text-gray-400 group-hover:text-${props.theme}`,
                        "ml-4 h-5 w-5"
                      )}
                      aria-hidden="true"
                    />
                    {item.name}
                  </a>
                </Link>
              ))}
            </nav>
          </div>
          {/* <div class="w-full ps-4 leading-9 mb-4">
          <div class="ps-2 self-start">
            <span class={` font-bold  text-normal text-${props.theme}`}>
              تطبيق وجيز
            </span>
          </div>
          <div class=" ps-2 self-start text-xs font-bold leading-5">
            <span> مع تطبيق وجيز صار التعلُّم ممتعاً دون جهد أو وقت طويل. استمع الى ملخصات الكتب في طريقك الى المنزل أو أثناء التمرين </span>
          </div>
        </div> */}
        </div>
        <a
          class="flex items-center  w-full h-16 mt-auto  "
        >
          
          
          <SidebarFooter/>

        </a>
      </div>
    </div>
  );
};

export default Sidebar;
