import React, { Fragment, useState,useContext } from "react";
// import App from "routes/index";
import { useRouteMatch, useHistory } from "react-router-dom";
import NavBar from "../NavBar";
import App from "../../routes/index";
import {useSelector} from 'react-redux';
import SideBar from '../sidebar/index'
const MainApp = () => {

  const [match, setMatch] = useState(useRouteMatch());
  const {themeColor, logo} = useSelector(({settings})=>settings)
  const {businessData} = useSelector(({auth})=>auth)
  
  return (
    <div dir='rtl' className="h-screen flex overflow-hidde bg-wihte ">
      <SideBar match={match} setMatch={setMatch} theme={themeColor} logo={logo} businessData={businessData}/>
      <div className="flex flex-col w-0 flex-1 overflow-hidden">
        <main className="flex-1  overflow-y-auto focus:outline-none">
          <div className='z-0 relative '>
            <App match={match} theme={themeColor} logo={logo} businessData={businessData} />
          </div>
        </main>
      </div>
    </div>
  );
};

export default MainApp;
