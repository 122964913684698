import React from 'react';
import {MdShowChart,MdDashboard} from 'react-icons/md'
import {FaBookReader,FaCog} from 'react-icons/fa'

const navigation = [
    
    { name: 'لوحة التحكم', href: '/dashboard', icon: MdShowChart, current: true },
    { name: 'الموظفون', href: '/users', icon: FaBookReader, current: false },
    { name: 'القسائم', href: '/vouchers', icon: MdDashboard, current: false },
    
    { name: 'الاعدادات', href: '/Settings', icon: FaCog, current: false },

    
]

export default navigation;