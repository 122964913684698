import {
  INIT_URL,
   SIGNOUT_USER_SUCCESS,
    USER_DATA, USER_TOKEN_SET,
    GROUP_ID,
    HEADER,
    USER_ID,
    BUSINESS_DATA,
    IS_DEMO,
    USER_PERMITIONS} from "../../constants/ActionTypes";
import getTimezoneOffset from '../../util/getTimeZoneOffset'

const INIT_STATE = {
  token: (localStorage.getItem('token')),
  initURL: '',
  isDemo:localStorage.getItem('isDemo') ||false,
  businessData: (JSON.parse(localStorage.getItem('business_data')))|| {name:''},
  authUser: (JSON.parse(localStorage.getItem('user'))),
  user_id: localStorage.getItem('user_id'),
  group_id: localStorage.getItem('group_id'),
  headers:{
    'Accept-Language':'ar',
    'Access-Control-Allow-Origin':'*',
    'Content-Type': 'application/json',
    'Origin': '*',
    'Access-Control-Allow-Headers': '*',
    timezone:getTimezoneOffset(),
    // 'accept': 'text/plain',
    'Authorization':localStorage.getItem('Authorization')}

};

export default (state = INIT_STATE, action) => {
  switch (action.type) {


    case INIT_URL: {
      return {...state, initURL: action.payload};
    }

    case IS_DEMO: {
      return {...state, isDemo: action.payload};
    }

    case SIGNOUT_USER_SUCCESS: {
      return {
        ...state,
        token: null,
        authUser: null,
        initURL: ''
      }
    }

    case USER_DATA: {
      return {
        ...state,
        authUser: action.payload,
      };
    }

    case USER_TOKEN_SET: {
      return {
        ...state,
        token: action.payload,
      };
    }

    case HEADER : {
      return{
        ...state,
        headers: action.payload
      }
    }

    case USER_ID: {
      return {
        ...state,
        user_id:action.payload
      }
    }

    case GROUP_ID: {
      return {
        ...state,
        group_id:action.payload
      }
    }

    case BUSINESS_DATA: {
      return {
        ...state,
        businessData:action.payload
      }
    }
    

    

    default:
      return state;
  }
}
