import React from 'react';
import {useSelector,useDispatch} from 'react-redux';
import {
  LogoutIcon,
  CogIcon,
  UserCircleIcon,
} from '@heroicons/react/outline'
import {userSignOut} from "../../appRedux/actions/Auth";
const SidebarFooter = () =>{
    const {authUser} = useSelector(({auth})=>auth)
    const dispatch = useDispatch();

    return(
        <div className="flex-shrink-0 flex  ursor-pointer p-4">
          <div className="flex items-center">
          
            <div className="mr-3">
              <a 
              onClick={() => dispatch(userSignOut())}
              className="text-base font-medium flex cursor-pointer"> <LogoutIcon
                                className="ml-3 h-6 w-6 text-gray-500 group-hover:text-gray-700"
                                aria-hidden="true"
                                /> 
                                تسجيل الخروج</a>
              {/* <p className="text-sm font-medium text-gray-500 ">مشاهدة الملف الشخصي</p> */}
            </div>
          </div>
      </div>
    )
}

export default SidebarFooter;