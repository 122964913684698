import React from "react";
import { useSelector } from "react-redux";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import asyncComponent from "../util/asyncComponent";

const App = ({ match, theme }) => {
  return (
    <>
      <Switch>
        <Route
          path={`${match.url}vouchers/users/:slug/:id`}
          component={asyncComponent(() => import("./voucherUsers"))}
        />

        <Route
          path={`${match.url}vouchers`}
          component={asyncComponent(() => import("./vouchers"))}
        />
        <Route
          path={`${match.url}reviews/user/:id`}
          component={asyncComponent(() => import("./reviews/userReviews"))}
        />
        <Route
          path={`${match.url}reviews`}
          component={asyncComponent(() => import("./reviews"))}
        />

        <Route
          path={`${match.url}usersProgress/:type`}
          component={asyncComponent(() => import("./usersProgress"))}
        />

        <Route
          path={`${match.url}dashboard`}
          component={asyncComponent(() => import("./dashboard"))}
        />
        <Route
          path={`${match.url}Settings`}
          component={asyncComponent(() => import("./Settings"))}
        />
        <Route
          path={`${match.url}users`}
          component={asyncComponent(() => import("./users"))}
        />
        <Route path={`${match.url}home`} component={null} />
      </Switch>
    </>
  );
};

export default App;
