import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import './tailwind.css'
import App from './App';
import reportWebVitals from './reportWebVitals';
import configureStore, { history } from './appRedux/store';
import { ConnectedRouter } from 'connected-react-router'
import { Provider } from 'react-redux'
import api from './util/Api'
import {userSignOut} from './appRedux/actions/Auth'
import "react-datepicker/dist/react-datepicker.css";


const store = configureStore(/ provide initial state if any /);

const router = (
  <Provider store={store}>
  <ConnectedRouter history={history}>
  <React.StrictMode>
    <App />
  </React.StrictMode>
  </ConnectedRouter>
  </Provider>
);

ReactDOM.render(
  router
  ,
  document.getElementById('root')
);

const UNAUTHORIZED = 401;
const {dispatch} = store; // direct access to redux store.
api.interceptors.response.use(
  response => response,
  error => {
    const {status} = error.response;
    if (status === UNAUTHORIZED) {
      dispatch(userSignOut());
    }
   return Promise.reject(error);
 }
);

reportWebVitals();
