import React from "react";

const SidebarHeader = (props) => {
  console.log("props", props);
  return (
    <div class=" w-full ps-2 py-7 mt-4 ">
      <div class=" align-middle space-y-2  justify-center text-center ">
        <p class="  mx-auto justify-center ">
          {props.props.logo ? (
            <img
              alt="logo"
              src={props.props.logo}
              class="h-auto w-14  rounded-xl mx-auto"
            />
          ) : (
            <svg
              class="w-8 h-8 fill-current"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 20 20"
              fill="currentColor"
            >
              <path d="M11 17a1 1 0 001.447.894l4-2A1 1 0 0017 15V9.236a1 1 0 00-1.447-.894l-4 2a1 1 0 00-.553.894V17zM15.211 6.276a1 1 0 000-1.788l-4.764-2.382a1 1 0 00-.894 0L4.789 4.488a1 1 0 000 1.788l4.764 2.382a1 1 0 00.894 0l4.764-2.382zM4.447 8.342A1 1 0 003 9.236V15a1 1 0 00.553.894l4 2A1 1 0 009 17v-5.764a1 1 0 00-.553-.894l-4-2z" />
            </svg>
          )}
        </p>
        <div
          className={
            "text-lg text-center  font-bold text-gray-900 align-middle"
          }
        >
          {props.props.businessData.name}
        </div>
      </div>
    </div>
  );
};

export default SidebarHeader;
