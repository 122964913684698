import React, { useEffect } from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";

import configureStore, { history } from "./appRedux/store";
import AppContent from "./containers/App/index";
import { useSelector, useDispatch } from "react-redux";
import { getBusinessData } from "./appRedux/actions/Auth";
const App = () => {
  const { group_id } = useSelector(({ auth }) => auth);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getBusinessData({ id: group_id }));
  }, []);

  return (
    <Switch>
      <Route path="/" component={AppContent} />
    </Switch>
  );
};

export default App;
